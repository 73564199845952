import React from 'react'
import { servicesData } from '../assets/data/servicesData'

function Services() {
  const menu = servicesData.map(item => (
    <div className="menu-item text-left mb-6 shadow-lg p-4 lg:py-4 lg:px-12 lg:basis-1/2 ">
      <p className="service-title font-bold text-left font-serif lg:text-xl">{item.title}</p>
      <p className="price  text-left mb-2 font-serif lg:text-lg">{item.price}</p>
      { item.description &&
        <p className="description text-sm text-left mb-4 lg:text-[16px]">{item.description}</p>
      }
    </div>
  ))
  
  return (
    <div id='menu'>
      <p className='text-3xl my-8 font-serif lg:text-4xl lg:my-12'>Services</p>
      <div className="menu lg:flex lg:flex-wrap">
        {menu}
      </div>
    </div>
  )
}

export default Services
