import React from 'react'
import { FiMapPin } from 'react-icons/fi'
import { AiOutlinePhone } from 'react-icons/ai'
import Map from './Map'

const Contact = () => {
  return (
    <div className="contact">
      <div id='get_in_touch' className='flex flex-col h-52 w-full  justify-around mb-20 mt-16'>
        <p className="text-3xl font-medium mb-8 font-serif">Get in Touch</p>
        {/* <p className="flex w-full items-center justify-center text-2xl font-medium"> */}
        <p className='text-2xl tracking-widest font-serif'>
          CASA DE REYNAS
        </p>
        <p className=" font-serif flex w-full items-center justify-center text-xl font-medium">
          <span className=''><FiMapPin/></span>&nbsp;&nbsp;10036 16th Ave SW Seattle, WA 98146</p>
        <a href="tel:2064235053" className=" font-serif flex w-full items-center justify-center text-xl font-medium">
          <span className=''><AiOutlinePhone/></span>
          &nbsp;&nbsp;(206) 710 - 7222</a>
        <p className='font-serif flex w-full items-center justify-center text-xl font-medium'>casadereynasbeauty@yahoo.com</p>
      </div>
      <Map/>
    </div>
  )
}

export default Contact
