import React from 'react'
import { Link } from 'react-router-dom'
import { AiOutlineFacebook, AiOutlineCopyrightCircle } from 'react-icons/ai'
import { BsInstagram } from 'react-icons/bs'
import LogoRound from '../assets/businessImages/gina-logo-round.png'
import { useNavigate } from 'react-router-dom'

const Footer = () => {
  const navigate = useNavigate()

  const toHome = () => {
    navigate('/')
    window.scrollTo(0,0)
  }

  return (
    <div className='flex items-center justify-around  bg-backGround text-goldText lg:justify-between'>
      {/* <Link to=''> */}
        <img src={LogoRound} alt="logo" className='h-12' onClick={()=> toHome()}/>
      {/* </Link> */}
      <p className='flex items-center'>
        <AiOutlineCopyrightCircle className='ml-1'/>
        &nbsp;Casa De Reynas
      </p>
      <div id="media" className='h-16 flex items-center justify-center '>
        <Link to='https://www.facebook.com/profile.php?id=61556267995453' className='text-4xl px-4'><AiOutlineFacebook/></Link>
        <Link to='https://www.instagram.com/casa_de_reynas_beauty/' className='text-3xl px-4'><BsInstagram/></Link>
      </div>
      {/* ADD MY TAG */}
    </div>
  )
}

export default Footer
