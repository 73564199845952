import React, {useState, useEffect} from 'react'
import { Routes, Route } from 'react-router-dom' 
import Home from './pages/Home'
import Nav from './components/Nav'
import Footer from './components/Footer'
import Gallery from './pages/Gallery'
import Contact from './components/Contact'
import Services from './pages/Menu'


// bg-gradient-to-b from-indigo-900 to-violet-900
const App = () => {
  // const [show, setShow] = useState(true)

  // useEffect(() => {
  //   setTimeout(()=> {
  //     setShow(!show)
  //   }, 1000)
  // }, [])
  return (
    <div className='App h-full w-full '>
      <Nav />
      <Routes>
        {/* errorElement: <ErrorPage />, */}
        <Route exact path="/" element={<Home />}/> 
        {/* CHANGED EXACT PATH TO INCLUDE SLASH / */}
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/services" element={<Services />} />
      </Routes>
      <Contact />
      <Footer/>
    </div>
  )
}

export default App
