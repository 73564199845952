import React from 'react'

const Map = () => {
  return (
    <div className='map-cont w-full h-52 '>
      <iframe
              className='map-cont w-full h-52 bg-yellow-300'
              title='map'
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2694.8316882717554!2d-122.35737592246367!3d47.51266897118131!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x549043e75eca3019%3A0x51e263d7746de9e2!2s10036%2016th%20Ave%20SW%2C%20Seattle%2C%20WA%2098146!5e0!3m2!1sen!2sus!4v1706695171628!5m2!1sen!2sus"
              // width="600"
              // height="450"
              style={{border: 0}}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
              loading='lazy'
              zoom={5}
            />
    </div>
  )
}

export default Map


