import React from 'react'
// import Logo from '../assets/businessImages/Gina-logo-head.png'
import Extensions from '../assets/images/extension.JPG'
import Haircuts from '../assets/images/redcurl.JPG'
import Hairdye from '../assets/images/blonde-highlights.jpg'
import Brazilian from '../assets/images/brazilian.JPG'
import BrowLamination from '../assets/images/brow.jpg'
import Perm from '../assets/images/perm.JPG'


const Services = () => {
  return (
    <div className='mt-10 mb-20 lg:flex lg:flex-wrap lg:justify-around'>
      <div className="service flex flex-col items-center mb-16 hover:scale-125 transform transition duration-500 ease-in-out lg:basis-1/3">
        <div className="w-72 h-72 rounded-full overflow-hidden mb-4 ">
          <img className='h-full w-auto scale-150 m-auto mt-2' src={Extensions} alt="" />
        </div>
        <p className="text-2xl">Hair Extensions</p>
      </div>
      <div className="service flex flex-col items-center mb-16 hover:scale-125 transform transition duration-500 ease-in-out lg:basis-1/3">
        <div className="w-72 h-72 rounded-full overflow-hidden mb-4">
          <img className='h-full w-auto scale-150 m-auto mt-2' src={Haircuts} alt="" />
        </div>
        <p className="text-2xl">Hair Cuts</p>
      </div>
      <div className="service flex flex-col items-center mb-16 hover:scale-125 transform transition duration-500 ease-in-out lg:basis-1/3">
        <div className="w-72 h-72 rounded-full overflow-hidden mb-4">
          <img className='h-full w-auto scale-150 m-auto mt-2' src={Hairdye} alt="" />
        </div>
        <p className="text-2xl">Hair Dyes</p>
      </div>
      <div className="service flex flex-col items-center mb-16 hover:scale-125 transform transition duration-500 ease-in-out lg:basis-1/3">
        <div className="w-72 h-72 rounded-full overflow-hidden mb-4">
          <img className='h-full w-auto scale-150 m-auto mt-2' src={BrowLamination} alt="" />
        </div>
        <p className="text-2xl">Brow Laminations</p>
      </div>
      <div className="service flex flex-col items-center mb-16 hover:scale-125 transform transition duration-500 ease-in-out lg:basis-1/3">
        <div className="w-72 h-72 rounded-full overflow-hidden mb-4">
          <img className='h-full w-auto scale-150 m-auto mt-2' src={Brazilian} alt="" />
        </div>
        <p className="text-2xl">Brazilian Blowout</p>
      </div>
      <div className="service flex flex-col items-center mb-16 hover:scale-125 transform transition duration-500 ease-in-out lg:basis-1/3">
        <div className="w-72 h-72 rounded-full overflow-hidden mb-4">
          <img className='h-full w-auto scale-[2] m-auto mt-2' src={Perm} alt="" /> 
          {/* custom scaling for above image :D */}
        </div>
        <p className="text-2xl">Perm</p>
      </div>
    </div>
  )
}

export default Services
