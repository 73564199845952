import React from 'react'
// import Images from '../'
import Extensions from '../assets/images/extension.JPG'
import Haircuts from '../assets/images/redcurl.JPG'
import Hairdye from '../assets/images/blonde-highlights.jpg'
import Brazilian from '../assets/images/brazilian.JPG'
import BrowLamination from '../assets/images/brazilian.JPG'
import Perm from '../assets/images/perm.JPG'
import RedCurl from '../assets/images/redcurl.JPG'
import Updo from '../assets/images/UpDo.jpg'

const Gallery = () => {

  const imagesData = [
    Extensions,
    Haircuts,
    Hairdye,
    Brazilian,
    BrowLamination,
    Perm,
    RedCurl,
    Updo
  ]

  const images = imagesData.map((img, i) => (
    <div className="div">
      <img src={img} alt="" />
    </div>
  ))

  return (
    <div>
      <p className="text-3xl">Gallery</p>
      <div className="gallery grid grid-cols-3 gap-1">
        {images}
      </div>
    </div>
  )
}

export default Gallery
