export const servicesData = [
  {
    title: "Hair Extensions",
    price: "Free Consultation",
    description: "Extensions are positioned close to the scalp by weaving, braiding, or bonding and may consist of real or synthetic hair. They are designed to add length and volume to the style. When applied professionally, extensions will mix and move naturally with any style.",
  },
  {
    title: "Eyelash Lift",
    price: "Starting at $45",
    description: "A lash lift curls your lash from base to tip so that you can see the full length.",
  },
  {
    title: "Brow Lamination",
    price: "Starting at $25",
    description: "Brow lamination involves the “perming” of your eyebrow hairs to provide a fuller, more even look.",
  },
  {
    title: "Makeup",
    price: "Free Consultation",
    description: "",
  },
// hair system
  {
    title: "Split Ends Treatment",
    price: "Starting at $60",
  },
  {
    title: "Shampoo",
    price: "Starting at $50",
    description: "Includes shampoo, conditioner, and blowout.",
  },
  {
    title: "Shampoo & Blowout",
    price: "Starting at $45",
    description: '',
  },
  {
    title: "Women's Haircut",
    price: "Starting at $45",
    description: "A customized haircut designed by your stylist to fit your individual style and preferences. Includes shampoo and conditioner.",
  },
  {
  title: "Men's Haircut",
  price: "Staring at $25",
  description: "A customized haircut designed by your stylist to fit your individual style and preferences. Includes shampoo and conditioner.",
  },
  {
    title: "Child's Haircut",
    price: "Starting at $20",
    description: "A customized children's haircut designed by your stylist to fin individual needs and preferences.",
  },
  {
    title: "Relaxer - Brazilian Blowout",
    price: "Starting at $175",
    description: "Brazilian Blowout improves the condition of the hair by creating a protective protein layer around the hair shaft to eliminate frizz and smooth the cuticle. Consultation preferred.",
  },
  {
    title: "Conditioning Treatment",
    price: "Starting at $35",
    description: "A formula that is designed to restore proteins and moisture in the hair. Often the client will wear a cap and be placed under the dryer for 10-20 minutes to increase the speed of penetration.",
  },
  {
    title: "Balayage",
    price: "Starting at $155",
    description: "A coloring process for a more natural look. Balayage is a French word meaning to sweep or to paint. It allows for a sun-kissed natural looking hair colour - similar to what nature gives us as children - with softer, less noticeable regrowth lines. The principal idea being less is more when creating soft, natural looks. This includes a shampoo and styling.",
  },
  {
    title: "Color",
    price: "Starting at $85",
    description: "Is the process of using a dye or tint to change the color of your hair to cover up the grey, or for cosmetic purposes.",
  },
]



// {
//   title: "",
//   price: "",
//   description: "",
// }