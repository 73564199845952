import React from 'react'
import Dummy from '../assets/businessImages/Salon-dummyImage.jpg'
import { Link } from 'react-router-dom'
const Blurb = () => {
  return (
    <div className='w-full h-full mb-4'>
      <img src={Dummy} alt="" 
        className='z-0 opacity-80'
        // need to fix the scale and image
      />
      <p className='z-10 text-xl relative mx-8 mt-8 mb-16 lg:mx-40 lg:my-20'>My passion for hair and beauty has followed me through the years and I am happy to now be able to fullfil my dream.  Specializing in hair extensions, balayages, and laminations. I welcome all your hair needs.</p>
    </div>
  )
}

export default Blurb
