import React, { useState, useEffect } from 'react'
import { Spin as Hamburger } from 'hamburger-react'
// import Logo from '../assets/images/gina-logo.PNG' // PNG png case sensative
// // import Logo2 from '../assets/images/Gina-noBG-logo.png'

// import LogoHead from '../assets/businessImages/Gina-logo-head.png'
// import LogoTitle from '../assets/businessImages/Gina-title-logo.png'
import { Link } from 'react-router-dom'
import { AiOutlineFacebook } from 'react-icons/ai'
import { BsInstagram } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import LogoRound from '../assets/businessImages/gina-logo-round.png'

const Nav = () => {
  const [menu, setMenu] = useState(false) 
  const [scrollDirection, setScrollDirection] = useState(null)
  const navigate = useNavigate()

  const toHome = () => {
    navigate('/')
    window.scrollTo(0,0)
  }

  useEffect(()=> {
    let lastScrollY = window.pageYOffset

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset
      const direction = scrollY > lastScrollY ? 
      "down" : "up"

      if(direction !== scrollDirection && (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)) {
        setScrollDirection(direction)
      }
      lastScrollY = scrollY > 0 ? scrollY : 0
    }

    window.addEventListener("scroll",
    updateScrollDirection)
    return () => {
      window.removeEventListener('scroll', 
      updateScrollDirection)
    }
  }, [scrollDirection])

  const openClose = () => {
    setMenu(prev => !prev)
  }

  return (
    <nav className={`sticky flex 
      ${scrollDirection === "down" ? '-top-24' : 'top-0'} 
      justify-between h-20 lg:h-24 bg-gradient-primary 
      transition-all duration-500 z-50`}
    >
      <img src={LogoRound} alt="" 
        className='z-50 h-24 m-2 lg:h-28'
        onClick={()=> toHome()}
      />
      <p 
        className='text-2xl flex items-center -ml-6 font-serif tracking-widest lg:text-5xl'
      >
        CASA DE REYNAS
      </p>
      <div 
        className={`
          h-0 -mt-30 absolute bg- text-goldText w-full duration-700 transition-all ease-in-out  
          text-opacity-0 bg-gradient-primary
          
          ${ menu ? 'active text-opacity-100 visible' : 'overflow-hidden' }
        
        `}
      >
        <a 
          className='h-16 text-xl flex items-center justify-center w-full' 
          href="tel:+12067107222"
        >
          (206) 710 - 7222
        </a>
        <div 
          id="media" 
          className='h-16 flex items-center justify-center'
        >
          <Link 
            to='https://www.facebook.com/profile.php?id=61556267995453'
            className='text-3xl px-4'
          >
            <AiOutlineFacebook/>
          </Link>
          <Link to='https://www.instagram.com/casa_de_reynas_beauty/' className='text-2xl px-4'><BsInstagram/></Link>
        </div>
        <Link
          onClick={openClose} 
          to='/'
          className='h-16 text-xl flex items-center justify-center w-full' 
        >
          Home
        </Link>
        {/* <Link 
          onClick={openClose}
          to='/gallery'
          className='h-16 text-xl flex items-center justify-center w-full' 
          >Gallery
        </Link> */}
        <Link 
          onClick={openClose}
          to='/services'
          className='h-16 text-xl flex items-center justify-center w-full' 
        >
          Services
        </Link>
        <Link 
          onClick={openClose}
          className='h-16 text-xl flex items-center justify-center w-full' 
        >
          Forms
        </Link>
        <p 
          className='h-16 text-xl flex items-center justify-center w-full hover:cursor-pointer' 
          onClick={(e) => {
            e.preventDefault()
            window.scrollTo({
              top: document.querySelector('#get_in_touch').offsetTop,
              behavior: "smooth",
            })
          }}
        >
          Contact
        </p>
      </div>
      <div 
        className="flex items-center mr-2 h-2/4 self-center" 
        onClick={openClose}
      >
        <Hamburger 
          color="white"
          size="32"
        />
      </div>
    </nav>
  )
}

export default Nav