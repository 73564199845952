import React, {useEffect, useState} from 'react'
import CDRlogo from '../assets/businessImages/CDR-logo.JPG'

export const SplashScreen = () => {
  const [show, setShow] = useState(true)

  useEffect(() => {
    setTimeout(()=> {
      setShow(false)
    }, 1000)
  }, [show])

  return (
    <div
      style={{
        background: '#6c0322',
        zIndex: 100,
        height: '100%',
        width: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: show ? '1' : '0',
        transition: 'opacity 1.8s ease-out'
      }}
    >
      <img  // image fast loader??
        src={CDRlogo}
        alt='Main Logo'
        style={{
          height: 450,
          width: 'auto',
          marginTop: -20,
          opacity: show? '1' : '0',
          transition: 'opacity 1.5s ease-out'
        }}
      />
    </div>
  )
}

