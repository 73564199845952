import React, { useEffect, useRef } from 'react'
// import SimpleImageSlider from 'react-simple-image-slider'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Parallax, EffectCards, Navigation } from 'swiper'
import 'swiper/css'
import { register } from 'swiper/element/bundle'
import Extension from '../assets/images/extension.JPG'
import RedCurl from '../assets/images/redcurl.JPG'
import Brazilian from '../assets/images/brazilian.JPG'
import Alan from '../assets/images/alan.jpg'
import Boy from '../assets/images/boy-blueShirt.jpg'
import Highlights from '../assets/images/blonde-highlights.jpg'
// import Mig from '../assets/images/mig_beard.jpg'
import UpDo from '../assets/images/UpDo.jpg'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

register()
const Slider = () => {
  const styles = {
    swiper: {
      "--swiper-pagination-color": "#c5b378",
      "--swiper-pagination-bullet-inactive-color": "#999999",
      "--swiper-pagination-bullet-inactive-opacity": "0.5",
      "--swiper-pagination-bullet-size": "10px",
      "--swiper-pagination-bullet-horizontal-gap": "6px",
      "--swiper-navigation-color": "#c5b378",
      "--swiper-nativation-opacity": "0.1",
      width: "100%",
      objectFit: 'cover',
      objecPosition: 'center',
    },
    image: {
      height: "100%",
      width: 'auto !important',
      objectFit: "cover",
    },
  }

  const windowWidth = window.innerWidth

  console.log(window.innerWidth,'SLIDER')

  return (
    <div className='h-[800px] w-full mb-10'>
      <Swiper
        style={styles.swiper}
        className='swiper-container max-h-full w-full m-auto '
        modules={[Navigation, Pagination, Parallax, EffectCards]}
        slidesPerView={windowWidth > 1000 ? 3 : 1}
        navigation
        pagination
        autoplay
        spaceBetween={50}
        loop={true}
        centeredSlides={true}
        height={700}
        speed={900}
      >
        <SwiperSlide>
          <img src={Extension} alt="" style={styles.image}/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={RedCurl} alt="" style={styles.image}/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={Alan} alt="" style={styles.image}/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={Brazilian} alt="" style={styles.image}/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={Highlights} alt="" style={styles.image}/>
        </SwiperSlide>
        {/* <SwiperSlide>
          <img src={Mig} alt="" style={styles.image}/>
        </SwiperSlide> */}
        <SwiperSlide>
          <img src={Boy} alt="" style={styles.image}/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={UpDo} alt="" style={styles.image}/>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default Slider
