import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import Slider from '../components/Slider'
import Services from '../components/Services'
import Blurb from '../components/Blurb'
import GetInTouch from '../components/Contact'
import Map from '../components/Map'
import Title from '../components/Title'
import {SplashScreen} from '../components/SplashScreen'

const Home = () => {
  const [show, setShow] = useState(true)

  useEffect(() => {
    setTimeout(()=> {
      setShow(false)
    }, 2000)
  }, [])

  // console.log(show, 'HOME')
  // if (show) {
  //   return (
  //     <>
  //       <SplashScreen />
  //     </>
  //   )
  // }

  return (
    <div id='home' className='flex flex-col items-center'>
      {show && 
        <>
          <SplashScreen />
        </>
      }
      <Slider />
      <Services />
      <Blurb />
    </div>
  )
}

export default Home
